/* Headers CSS */
.header-main {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
}


.header-left {
    font-size: 31px;
    color: $mid-grey;
    cursor: pointer;
    font-weight: lighter;
    position: relative;
}
.header-right {
    font-size: 31px;
    color:$primary;
    cursor: pointer;
    font-weight: bold;
    position: relative;
}
.header-small-screens {
    font-size: 15px;
}

.header-login {
    padding: 3px 30px 3px 30px;
    border: 1px solid $primary;
    color: $primary;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
}
.header-second {
    color: $dark-grey;
    font-size: 19px;
    font-weight: 500;
}

.pointer { cursor: pointer; }

.highlight {
    text-decoration-line: underline;
    text-decoration-color: $primary;
}
