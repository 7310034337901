.search-radio-btn-landing {
    /* grid-template-columns: 0.9fr 1fr 0.5fr;
    display: grid;
    padding-top: 5px;
    padding-left: 40px; */
    display: flex;
    justify-content: center;
    background-color: white;
    z-index: 999 !important;
    border: 1px solid lightgrey;
    width: 70%;
    margin-left: 5.5%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px 20px 0 0;
}

.search-radio-btn-sidepanel {
    display: flex;
    justify-content: center;
    background-color: white;
    z-index: 999 !important;
    border: 1px solid #43B02A;
    width: 60%;
    margin: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px 10px 0px 10px;
}