
@import './variables';

.login-height {
    min-height: calc(100vh - 125px);
}

.login-btn {

    background-color: $primary !important;
    border-color: $primary;
    color: white !important;
}

.login-text {
    color: white;
    white-space: nowrap;
}

.login-bg {
    background-color: $primary !important;
}

.icon-auth {
    position: absolute;
    right: 10px;
    color: $primary;
}

.password-icon-register{
    top: 45px;
}

.password-icon-login{
    top:12px;

}

.invalid-field-label{
    font-size: 80%;
    margin-top:5px;
    color: #dc3545;
}