.sidepanel
{
  &-container {
      z-index: 1;
      position: relative;
      display: block;
      float: right;
      right: 45px;
      top: 20px;
      width: 100% !important;
      padding-bottom: 50px;
      @media screen and (max-width: 600px) {
        right: 0px;
      }
  }
  &-panel {
    width: 100%;
    border-top: 4px solid $primary;
    background-color: white;
  }

  &-button {
      border: 1px solid $red;
      padding: 5px 15px 5px 15px;
      text-align: center;
      color: $red;
      font-size: $font-11;
      border-radius: 25px;
      background-color: white;
  }

  &-ful-btn {
      border: 1px solid $red;
      padding: 5px 15px 5px 15px;
      text-align: center;
      font-size: $font-11;
      color: white;
      border-radius: 25px;
      background-color: $red !important;
  }

  &-input {
    min-width: 100%;
    padding: 7px;
    outline-style: none;
    box-shadow: none;
    border-radius: 20px;
    border-color: transparent;
    border: none;
    text-indent: 10px;
    border:1px solid lightgrey;
  }

  &-search {
    top: 8px;
    right: 15px;
    position: absolute;
    cursor: pointer;
    bottom: 100px;
  }
  &-close-icon {
    position: absolute;
    top: 32px;
    left:-40px !important;
  }
  &-with-down {
    position: absolute;
    top: 93px !important;
  }
  &-hide-panel {
    position: absolute;
    right:-12px;
    top: 40px;
    transform: rotate(180deg);
  }
  &-future {
    color: white !important;
    font-size: $font-11;
    background-color: $dark-grey !important;
  }
  &-current {
    color: $mid-grey !important;
    font-size: $font-11;
    background-color: white !important;
  }

  &-chevron {
    font-size: 15px;
    color: $mid-grey;
    transform: translateY(-3px);
  }
  &-chevron-rotate {
    font-size: 15px;
    color: $mid-grey;
    transform: translateY(-3px) rotate(180deg) ;
  }
  &-inside {
    position: relative;
    background-color: $light-grey;
    height: 100%;
    width: 100%;
  }
  &-sm-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid grey;
  }
  &-input-error {
    width: 100%;
    border-radius: 5px;
    border: 1px solid red;
  }

  &-section:hover {
    background-color: $light-grey !important;
  }
  &-radioList {
   display:flex;
   flex-direction:column;
  }
  &-vertical {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
  }
  &-multi-label {
    padding-left: 35%;
    transform: translateY(-2px);
  }
  &-multi-label-span {
    border-radius: 50%;
    font-size: 14px;
    background: white;
    padding: 1px 9px;
    //border: 1px solid black
  }
  &-H-center {
    display: flex !important;
    justify-content: center !important;
  }
  &-mandatory {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    font-size: 13px;
    color: gray;
  }
  &-add {
    position: absolute;
    top: -13px;
  }
  &-add-icon {
    padding: 0px 5px 1.5px 5px;
    color: white;
    background: #53565A;
    border-radius: 50%;
  }
  
&-slider {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

&-label {
  width: 50px;
  display: block;
}

&-input[type=checkbox] {
  margin-left: 0px;
  margin-right: 0px;
}

&-li {
  flex: 0 1 33%;
  list-style: none;
  position: relative;
}

&-li:first-child:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 20%;
  background: black;
  top: 12px;
  right: 100%;
}

&-li:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: black;
  top: 12px;
  left: 25% !important;
}

&-li:last-child:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 20%;
  background: black;
  top: 12px;
  left: 100%;
}

&-btn-center {
  background-color:#9CA5B1 ;
  padding: 3px 20px;
  color: white;
  font-size: 15px;
  border: 1px solid white;
}

&-btn-left {
  @extend .sidepanel-btn-center;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

&-btn-right {
  @extend .sidepanel-btn-center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

&-active-border {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

}