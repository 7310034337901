.simulation-page {
  min-height: calc(100vh - 80px);
  margin-top: 80px;
}

th {
   background-color:$primary;
   color:white;
   border-color: $light-grey;
   text-align: center !important;
   font-weight: bold;
   font-size: 20px;
  }
  
  td, th {
    border: 1px solid none;
    padding: 8px;
    text-align: center;
  }
  
  tr:nth-child(even) {
    background-color: $light-grey;
  }

  .MuiTableCell-head {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    line-height: 1.5rem;
    font-weight: bold !important;
    font-size: 17px !important;
    color: white !important;
  }

  .new-sim-button {
    background-color: green !important;
    border: none;
  }