

/* Footer CSS*/

.footer {
  &-container {
  // height: 30px;
  width: 100%;
  background-color: $dark-grey;
  color:white;
  position: relative !important;
  bottom: 0 !important;
  display: block;
  }

  &-fixed {
    position: fixed
  }
  
  ul {
    padding:0;
    list-style:none;
    text-align:right;
    font-size:18px;
    line-height:1.6;
    margin-bottom:0;
    @media (max-width: 500px) {
      text-align: center;
      width: 100%;
    }
  }
  
  li {
    padding:0 10px;
    color: white !important;
    opacity: 1;
  }
  
  ul a {
    color:inherit;
    text-decoration:none;
    color: white;
    font-size: 14px;
  }
  
  &-copyright {
    margin-left: 20px;
    text-align:right;
    align-self: center;
    font-size:13px;
    color:lightgray;
    margin-bottom:0;
    margin-top: 2px;
    @media (max-width: 500px) {
      display: none;
    }
  }
  &-copyright-responsive {
    display: none !important;
    @media (max-width: 500px) {
      text-align: center;
      display: block !important;
      padding-bottom: 10px;
    }
  }

  &-company-div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 500px) {
      text-align: center;
      padding-top: 10px;
    }
  }

  &-company {
    cursor: pointer;
    color: $primary;
  }

  &-company:hover {
    color: $primary;
    text-decoration: none;
  }

  &-vl {
    border-left: 2px solid $primary;
    height: 14px;
    display: inline-block;
  }

}