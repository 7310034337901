.app-content-wrapper {
    min-height: 100vh;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    //-webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;
}

.simulation-card {
    border-top: 4px solid $primary;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 100px;
}

.zoning-card {
    width: 100%;
    height: 50%;
}

.simulation-card-header {
    background-color: $light-grey;
    height: 60px;
}

.info-card {
    background-color: $light-grey;
    height: 100%;
    padding-left: 10px !important;
}

.small-card {
    width: 150px;
}

.medium-card {
    width: 200px;
}

.large-card {
    width: 250px;
}

.x-large-card {
    width: 375px;
}

.half-card {
    width: 312px;
}

.full-card {
    width: 648px;
}

.inner-card-text {
    font-size: x-large;
    color: $dark-grey;
    font-weight: bolder;
}

.card-title {
    color: $dark-grey;
    font-weight: bold !important;
}

.two-unit-right {
    position: relative;
    bottom: 22px;
}

.two-unit-left {
    position: relative;
    bottom: 22px;
}


.units-text {
    font-size: small;
}

.bg-green {
  background-color: $light-green;
}

.text-green {
    color: $primary !important;
}

.bg-grey {
    background-color: gray;
}

.bg-orange {
    background-color: orange;
}

.text-red {
    color: red !important;
}
.ellipses {
    white-space: nowrap;
    width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.w-90percent {
    width: 90%;
}

.comingsoon-container {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.comingsoon-img {
    height: 300px;
    width: 300px
}

.cyan-bg {
    background-color: #e8e8e8;
    //backdrop-filter: blur(1px);
    //backdrop-filter: opacity(20%);
}

.w-55percent {
    width: 55%;
}

.h-150 {
    height: 150px;
}

.text-small {
    font-size: small !important;
}

.text-x-small {
    font-size: x-small !important;
}

.overflow-y {
    overflow-y: scroll;
}

.list-card {
    max-height: 130px;
}

.width-90pc {
    width: 90%;
}

.width-10pc {
    width: 10%;
}