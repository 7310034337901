.economic-circle {
    height: 45px;
    width: 50px;
    border-radius: 50%;
    border: 7px solid $primary;
    background-color: #FFFFFF;
    position: relative;
    left: 7px;
    top: -4px;
    z-index: 9;

    .small {
        //height: 25px;
        //width:
    }
}
.economic-circle2 {
    @extend .economic-circle;
    border: 1px solid $primary;
}

.economic-circle3 {
    @extend .economic-circle2;
    border: 1px solid $primary;
    z-index: 999 !important;
}
  
.economic-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $primary;
    font-size: 20px;
    margin-right: 2px;

    .small {
        font-size: 14px;
    }
}

.economic-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 13px;
    font-weight: bold;
    color: #FFFFFF;
}
.economic-text2 {
   @extend .economic-text;
   color: $primary;
}

.economic-text3 {
    @extend .economic-text2;
    color: $primary;
    padding-top: 5px
 }

.economic-light-text {
    @extend .economic-text3;
    font-size: 11px !important;
    font-weight: normal;
    padding-top: 0px
}
  
.economic-step1 {
    height: 35px;
    width: 100%;
    margin-top: 6px;
    background-color: $primary;
    position: absolute;
    right: 0px;
    width: 91%;
    @media screen and (max-width: 600px) {
        width: 93%;
    }
    @media screen and (max-width: 1120px) {
        width: 94%;
    }
}
  
.economic-step2 {
    @extend .economic-step1;
    background-color: #FFFFFF;
    border: 1px solid $primary;
    border-left: none;
    z-index: 99;
    width: 91%;
    @media screen and (max-width: 1150px) {
        width: 93%;
    }
    @media screen and (max-width: 800px) {
        width: 91%;
    }
    @media screen and (max-width: 600px) {
        width: 93%;
    }
}

.economic-step3 {
    @extend .economic-step2;
    height: 130px;
    border: 1px solid $primary;
    width: 94.5%;
    @media screen and (max-width: 1150px) {
        width: 96%;
    }
}


/* Main Card */

/* Card-1 */
.card-container {
    z-index: 1001;
    padding-top: 10px;
    position: relative;
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border: none;
    @media screen and (max-width: 600px) {
    }
}

.adjusted-height-ev-card {
    height: 600px;
}

.card-text-height {
    min-height: 59px !important;
    display: block;
}

.bar-container {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.inside-container {
    position: relative;
    width: 100%;
}
.section-heading {
    font-size: 14px;
    color: $light-text;
    font-weight: bold;
    @media screen and (max-width: 1000px) {
        font-size: x-small;
    }
}
.section-text {
    font-size: 10px !important;
    color: $card-text;
}

.section-text-2 {
    font-size: 12px;
    color: $card-text !important;
    position: relative;
    bottom: 10px;
}
.section-text-3 {
    font-size: 8px;
    color: $card-text-2;
    font-weight: normal !important;
}
.section-text-4 {
    font-size: 10px;
    font-weight: bold;
    color: $light-text;
}

.section-text-5 {
    font-weight: bold;
    color: $light-text;
}

.card-section-text-colored {
    @extend .inside-container;
    color: $secondary;
    font-size: 20px;
    font-weight: bold;
    white-space: pre;
    display: block;
    @media screen and (max-width: 1000px) {
        font-size: small;
    }

}

.card-section-text-colored-2 {
    @extend .card-section-text-colored;
    font-size: 12px;
    color: $card-text !important;
    top: 10px;
}

.card-bar {
    @extend .inside-container;
    align-self: center;
}

.bar-label {
    @extend .section-text;
    position: relative;
    width: 20% !important;
    text-align: right;
    padding-right: 10px;
}
.section2-text {
    @extend .section-text;
    padding: 0% 10%;
    font-weight: bold;
    color: $light-text;
    text-align: center;
}
.section1-label {
    font-size: 12px;
    color: $card-bar;
}

.card-section {
    padding: 0px 20px;
}


/* Card-2 */

.card2-section1-container {
    position: relative;
    padding-top: 14.2px;
}

.card2-section2-container {
    position: relative;
    padding-bottom: 1px;
}

.card2-section3-container {
    position: relative;
    padding-bottom: 0px;
}



.card2-section-bar-bottom-text {
    @extend .section-text;
    padding-top: 2px;
    float: left;
}
.card2-section-bar-bottom-text-2 {
    @extend .card2-section-bar-bottom-text;
    float: right !important;
}

.card2-setting-container {
    text-align: left;
    margin-top: 35%;
}

.card2-section-text-colored {
    @extend .card-section-text-colored;
    color: $primary !important;
}

.card2-section-text-colored-2 {
    @extend .card2-section-text-colored;
    font-size: 12px;
}

.card2-setting-text {
    @extend .section2-text;
    padding: 0px;
}




.bottom-card-container {
    @extend .card-container;
    padding: 20px 10px;
    width: 101%;
}

/* Slider Material UI Class */
.MuiSlider-markLabel {
    color: $slider-text !important;
    font-size: 10px !important;
}

.PrivateValueLabel-circle-17 {
    color: #FFFFFF !important;
    background-color: $secondary !important;
}
.PrivateValueLabel-circle-65 {
    @extend .PrivateValueLabel-circle-17;
}
.MuiSlider-markLabel {
    top: 9px !important;
    transform: translateX(5px) !important;
}

.MuiSlider-markLabelActive {
    left: -20px !important;
    transform: translateX(0)  !important;
}

.MuiSlider-marked {
    margin-bottom: 0px !important;
}

/* Slider Material UI Class  END */


.custom-track-label {
    position:absolute;
    left:0px;
    top: -5px;
    font-size:10px;
    font-weight: bold;
    color: $light-text;
}




.container-exit {
    position: absolute;
    z-index: 10002;
    //top: 100px;
    right: 5px;
    cursor: pointer;

}
.container-top {
    margin-top: 20px;
}

.container-hide {
    display: none !important;
}


.euro-yearly {
    @extend .section-text-2;
    position: relative;
    bottom: 3px;
}

.bottom-card-inputs {
    width: 100%;
    overflow: visible;
    border: 1px solid $secondary;
    text-indent: 10px;
}