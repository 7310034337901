.rounded-span {
    text-align: center;
    color: white;
    padding: 3px 20px 3px 20px;
    background-color: $dark-grey;
    border: 1px solid $dark-grey;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    font-size: $font-11;
    cursor: pointer;
}

.rounded-span2 {
    text-align: center;
    color: $mid-grey;
    padding: 3px 15px 3px 15px;
    background-color: white;
    font-size: $font-11;
    border-left: 1px solid $dark-grey !important;
    border: 1px solid $mid-grey;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
}