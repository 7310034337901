@import './variables';
@import './fonts.scss';
@import './colors.scss';
@import './header.scss';
@import './homepage/landing.scss';
@import './homepage/container.scss';
@import './login.scss';
@import './sidePanel.scss';
@import './modificationBuiltin.scss';
@import './shared.scss';
@import './footer.scss';
@import "./app.scss";
@import "./simulation.scss";
@import "./zoning.scss";
@import './infoPage.scss';
@import "./errors.scss";
@import "./economicViability.scss";

body, html { 
  font-family: Barlow !important;
}

//@media only screen and (max-width: 950px) {
//  body {
//    padding-top: 60px;
//  }
//}