// Colors
$primary : #43B02A;
$secondary:#702082;
$light-green:#43B02A3B;
$light-grey:#53565A10;
$mid-grey:gray;
$dark-grey:#53565A;
$red:#E03C31;
$black:#000000;
$light-purple:#70208208;
$light-text:#525252;
$bg-grey:#F5F5F5;
$bg-grey2:#F3F3F3;

// Card-Map
$card-text:#9AA1A9;
$card-text-2:#9CA3AB;
$card-bar:#B3B4B6;
$slider-text:#53565A7E;

// Font
$font-11:11px;