h4, .h4 {
    /* font-size: 1.5rem; */
    font-size: 15px;
    font-weight: bold;
}

/* Multicolor Labels */
small, .small {
    font-size: 11px;
    font-weight: 400;
  }

  
textarea:focus, input:focus {
  outline: none;
}

 /* Material UI Text-Field*/
 .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 250px !important;
  border: 0;
  height: 2em;
  margin: 0;
  display: block;
  padding: 20px !important;
  min-width: 0;
  background: white !important;  
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}


.MuiInputBase-root {
  color: rgba(0, 0, 0, 0.87);
  background-color: white !important;
  cursor: text;
  width: 285px;
  height: 59px !important;
  padding-top: 4px !important;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}


 /* Material UI DatePickers */

.MuiPickersDay-daySelected {
  color: #fff;
  font-weight: 500;
  background-color: $primary !important;
}

.MuiPickersToolbar-toolbar {
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: $primary !important;
}

.MuiButton-textPrimary {
  color: $primary !important;
}

.MuiTypography-colorPrimary {
  color: $primary !important;
}

.MuiFilledInput-inputAdornedEnd {
  margin-top: 12px !important;
}

.MuiInputLabel-shrink {
  z-index: 999 !important;
  transform: translate(0, 18px) scale(0.75) !important;
  transform-origin: top left;
}

.MuiFormHelperText-root {
  display: none !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
  font-family: 'Barlow' !important;
}

.MuiTypography-body1 {
  font-family: 'Barlow' !important;
}

.MuiFormLabel-root {
  font-family: 'Barlow' !important;
}

.MuiButton-root {
  font-family: 'Barlow' !important;
}
/* SELECT DROPDOWN */

.MuiMenu-paper {
  max-height: 400px !important;
  -webkit-overflow-scrolling: touch;
}

/* MENU DROPDOWN */
.MuiList-padding {
  padding-top: 8px !important;
  padding-bottom: 0px !important;
}

/* AUTOCOMPLETE */

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 56px !important;
  width: 285px !important;
}

/* MODAL INFO PAGE */
.react-responsive-modal-modal {
  min-width: 90%;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}


// FORM SELECT 

.form-select {
  width: auto !important; 
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}
