.zoning {
   &-fields {
       display: flex;
       align-items: center !important;
       justify-content: center;
       flex-direction: row;
       min-height: calc(100vh - 29px);
       margin-top: 50px;
   }
   &-upload-div {
       display: flex;
       align-items: center;
       justify-content: center;
   }
   &-upload-msg {
       @extend .zoning-upload-div;
   }
   &-msg-text {
       color: $mid-grey;
       font-size: 16px;
   }
   &-msg-text2 {
    color: $black !important;
    font-weight: bold !important;
    font-size: 14px !important;
}
   &-upload-btns {
       padding: 10px 25px;
       border: none;
       color: white;
       background-color: $primary;
       border-radius: 10px;
   }
   &-remove-btn {
       padding: 8px 20px;
       border: none;
       background-color: $mid-grey !important;
       color: white !important;
       border-radius: none !important;

   }
   &-submit {
        @extend .zoning-upload-btns;
        background-color: $mid-grey !important;
}
   &-select {
       position: relative;
       top: 4px;
   }
   &-dGap {
       position:relative;
       bottom: 7px;
   }
}
