/* Containers CSS*/

.info-page-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.info-page-modal-image {
    width: 100%;
    height: 85vh;
}

.info-page-heading {
    color:$secondary;
    font-weight: bold;
    font-size: 23px;
}

.info-page-content {
    font-size: 15px !important;
    color: black;
}

.info-page-vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.info-page-grid-img {
    width: 70px !important;
    height: 70px !important;
}

.info-page-grid-head {
    color: $light-text;
}


.info-page-grid-para {
    font-size: 14px;
    color: $light-text;
}

.info-page-bg-grey { background-color: $bg-grey2;}

