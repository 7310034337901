/* Containers CSS*/

.container-image {
    width: 100%;
    height: 100%;
}

.container-line-img {
    position: relative;
    bottom: 5.5%;
    left: 0px;
}

.container-heading {
    color:$secondary;
    font-weight: bold;
    font-size: 35px;
}

.container-content {
    font-size: 17px;
    color: $dark-grey;
}

.container-content-btn {
    padding: 7px 20px 7px 20px !important;
    border: 1px solid $red !important;
    color: white;
    background-color: $red;
    border-radius: 30px !important;
    cursor: pointer;
}
.container-vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.container-grid-img {
    width: 127px !important;
    height: 127px !important;
}

.container-grid-head {
    color: $light-text;
}


.container-grid-para {
    font-size: 14px;
    color: $light-text;
}

.container-grid-btn {
   font-size: 15px;
   color: $red !important;
   font-weight: bold;
   text-decoration-line: underline;
   cursor: pointer;
}

.circled-border {
    border: 1px solid transparent;
    display: block;
}

.circled-border:hover {
    border: 1px solid $light-grey;
    border-radius: 50%;
    cursor: pointer;
    width: 100%;
}


.conatiner-bg-grey { background-color: $bg-grey}



@media only screen and (max-width: 1150px) {
    .container-line-img {
        position: relative;
        bottom: 5.3%;
        left: 0px;
    }
}

@media only screen and (max-width: 960px) {
    .container-line-img {
        position: relative;
        bottom: 3%;
        left: 0px;
    }
}

@media only screen and (max-width: 850px) {
    .container-line-img {
        position: relative;
        bottom: 17px;
        left: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .container-line-img {
        position: relative;
        bottom: 2px;
        left: 0px;
    }
}

