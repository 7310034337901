
@import './variables';


/* Landing CSS */

html {
    scroll-behavior: smooth;
}

.landing-image {
    position: relative;
    width: 100%;
    height: 88vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../../assets/map.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-right-radius: 50% 10%;
    border-bottom-left-radius: 50% 10%;
}
    

.landing-bottom-circle {
    position:absolute;
    bottom: -15px;
    background-color: $red;
    border-radius: 50%;
    padding: 5px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 0.1;
    cursor: pointer;
}

.bottom-icon {
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding-top: 15px !important;
}

@keyframes arrowDown {
    0%   {position: relative}
    100% {padding-top: 10px;}
}

.arrow-icon {
  padding-right: 0.5px;
  &:hover {
    animation-name: arrowDown;
    animation-duration: 1s;
  }
}


.landing-container {
    margin-top: 8%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    z-index: 111;
    padding: 30px;
    border-radius: 10px;
    background:rgba(255,255,255, 0.6);
}

.landing-container-heading {
    font-size: 32px;
    font-weight: bolder;
    padding: 0px 10px;
    color: $primary;
}

.landing-font {
    font-size: 15px;
    color: $dark-grey;
}

.search-bar {
    height: 40px;
}

.landing-btn {
    padding: 7px 15px 7px 15px;
    height: 40px;
    border: none;
    color: white;
    background-color: $primary;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.landing-btn-container
{
    display: flex;
    justify-content: center;
}

.landing-input {
    height: 100%;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    border: none;
    text-indent: 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border:1px solid lightgrey;
}

.landing-input-size {
    width: 100%;
    font-size: xx-small !important;
}

@media only screen and (min-width: 800px) {
    .landing-input-size {
        width: 300px;
    }
}

.right-round {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.search-bottom {
    font-weight: bolder;
    font-size: 16px;
    text-decoration-line: underline;
    color:$secondary;
}

.listbox {
    cursor: pointer;
    margin-top: 40px;
    width: 300px;
    padding: 0;
    z-index: 1;
    position: absolute;
    list-style: none;
    background-color: #f3f3f3;
    overflow: auto;
    max-height: 200px;
    border: 1px solid rgba(0,0,0,.25);
  }

.text-small {
    font-size: small;
}

/* FAQ CSS */

.faq-heading {
    color:$secondary;
    font-weight: bold;
    font-size: 35px;
}

/* Loader CSS */

.center-fixed {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 111;
    background: rgba(0, 0, 0, 0.3);
}


/* Color Classes */

.primary-clr { color: $primary; }
.secondary-clr { color: $secondary; }
