.map-page-container {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    margin-top: 61px;
    /*position: relative;*/
}

.map-container {
    display: block;
    height: calc(100vh - 51px);
    position: fixed;
    top: 53px;
    width: 100%;
}

.ev-card-position {
    margin-top: 70vh;
}

.inner-map {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    touch-action: pan-x pan-y;
}

.inner-map-squeezed {
    width: 40%;
}

.inner-map-small-screen {
    height: 50%;
}

.content-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.content-wrapper-inside {
    position: relative;
}

.content-above {
    width: 100%;
    min-height: 40vh;
    padding-right: 10px;
    position: relative;
    /*top: 0.5%;*/
    /*left: 0;*/
    pointer-events: none;
}

.content-below {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.map-page-content {
    margin: 0 auto;
    max-width: 60%;
    position: relative;
    z-index: 1;
}

.h-full {
    height: 100%;
}

.w-full {
    width: 100%;
}

.rounded-corners {
    border-radius: 25px;
    border: 1px;
}

.rounded-corners-left {
    border-radius: 25px 0 0 25px;
    border: 1px;
}

.rounded-corners-right {
    border-radius: 0 25px 25px 0;
    border: 1px;
}

.text-grey {
    color: gray;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #d8d8d8;
    border-radius: 50%;
    display: inline-block;
}

.dot-bigger {
    height: 40px;
    width: 40px;
}

.blue-bg {
  background-color: #8084c0;
}

.hidden {
    visibility: hidden;
}

.map-controls-container {
    position: relative;
    top: 0;
    left: 0;
    pointer-events: all;
}

.question-mark-map {
    position: fixed;
    top: 180px;
    left: 7px;
    z-index: 1000;
}

.toggle-service-button {
    position: fixed;
    top: 70px;
    z-index: 1000;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-small;
}

.info-card-map {
    width: 50%;
    left: 25%;
    position: absolute;
    bottom: 0;
}

.popup {
    text-align: left;
    font-size: small;
    color: white;
}

.leaflet-popup-content {
    margin: 10px 15px !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: #797b7b;
}

.zoom-right-aligned {
    margin-right: 32% !important;
}

.zoom-controls {
    top: 50px !important;
}

.add-point-active {
    cursor: crosshair;
}

.pointer-on-map {
    cursor: pointer;
}

.popup-button {
    background: #dddddd;
    color: black;
    border-radius: 6px;
}

.legend-div {
    margin-top: 100px;
}